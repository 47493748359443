<template>
  <PxHeader :activeSearchMobile="false" />

  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section class="sizeMinAllView" v-else>
    <publish-button />
    <div
      class="flex items-center bg-agrogo-yellow-1 h-16 pl-6 md:h-16 md:pl-40"
    >
      <div
        class="font-nexa font-normal text-base text-agrogo-green-2 text-left md:text-2xl"
      >
        <div class="style-info" v-html="record.title" />
      </div>
    </div>
    <div>
      <img
        class="hidden md:flex object-cover min-h-contain-aboutus w-full"
        :src="loadImg(record.img_head_url)"
        alt=""
      />
      <div class="container-bg-aboutus-desktop">
        <div class="bg-agrogo-green-2 bg-agrogo-green-2-transparent">
          <div class="container-aboutus m-0 p-0 mr-0">
            <div class="md:hidden">
              <img class="" :src="loadImg(record.img_head_url)" alt="" />
            </div>
            <div class="mx-6 pt-7 pb-9 md:mx-12 md:pt-16 md:pb-16">
              <div v-for="(item, index) in record.page_content" :key="index">
                <div
                  class="font-nexa font-regular text-agrogo-yellow-1 text-base text-left pb-8 md:text-3xl"
                  v-html="item.content"
                  v-if="item.name_id == 'QS_P_2'"
                />
                <p
                  v-else
                  class="font-nexa font-normal leading-6 text-white text-xs text-left pb-9 md:text-xl md:leading-5"
                  v-html="item.content"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
// components
import PxHeader from "@/components/header/PxHeader";
import PublishButton from "@/components/publishButton/PublishButton";

export default {
  name: "AboutUs",
  inject: ["provider"],
  components: {
    PxHeader,
    PublishButton,
  },
  data() {
    return {
      repository: this.provider.publishFarmRepository,
      isBusy: true,
      record: {},
    };
  },
  methods: {
    async loadData() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllNames("QUIENES_SOMOS", dataAditional);
        me.record = data;
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    loadImg(url) {
      let img = null;
      img = url
        ? `${API_ROUTES.image.get}${url}`
        : require("@/assets/images/paisajeaboutusdesktop.jpg");
      return img;
    },
  },
  mounted() {
    const me = this;
    window.scroll(0, 0);
    me.loadData();
  },
};
</script>
